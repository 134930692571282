// --------------------
// BUTTON STYLE
//---------------------

.btn,
button,
input[type=submit],
input[type=button] {
    width: 100%;
    border: 0;
    border-radius: 5px;
    margin-bottom: 7px;
    white-space: normal;
    background: $primary;
    color: #fff;
    transition: background-color .4s ease-in-out;

    font-style: normal;
    font-weight: bold;
    font-size: 1.55rem;
    line-height: 1.35;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    box-shadow: 0px 30px 30px -20px rgba(94, 192, 155, 0.2);
    background: rgba(94, 192, 155, 1);
    text-shadow: 0 0 4px rgba(80,80,80,0.8);
    padding: 24px;
    // @include media-breakpoint-up(md) {
    //     padding: 30px;
    // }
    &:hover,
    &:focus,
    &:active {
        color: #fff;
        background: rgba($primary, .8);
    }
}

.btn-section {
    z-index: 100000;
    margin: 34px auto;
    display: block;
    max-width: 400px;
    p {
        width: 90%;
        margin: 0 auto;
        font-size: 1.3rem;
        line-height: 1.46;
        text-align: center;
    }
}

#ebook .btn-section {
    max-width: 500px;
}