.accordion {
    dt {
        cursor: pointer;
        background: #ffffff;
        padding: 20px 40px 20px 20px;
        margin-bottom: 19px;
        position: relative;
        background: #ffffff;
        box-shadow: -8px 46px 40px -17px rgba(94, 192, 155, 0.1);
        border-radius: 10px;
        font-family: DM Serif Text;
        font-weight: normal;
        color: #353739;

        &::before {
            content: '+';
            position: absolute;
            top: 20px;
            right: 20px;
            font-family: $body-font;
            font-weight: bold;
            text-align: center;
            color: $highlights;
            text-shadow: 0px 4.31184px 14.3728px rgba(94, 192, 155, 0.52);
        }
        &.active {
            margin-bottom: 0;
        }
        &.active::before {
            content: '-';
            top: 19px;
            right: 22px;
        }
    }
    
    dd {
        display: none;
        padding: 19px;
    }
}

