header {
    padding: 15px;
    background-color: transparent;
    &.scrolled {
        box-shadow: -3px -3px 6px 8px rgba(0, 0, 0, 0.05);
        background-color: $headerbg;
        transition: 0.2s ease-in-out;
    }
}

.header-logo img {
    transition: 0.2s ease-in-out;
    // Inverts the logo colour. You could use the filter options to modify a transparent png logo for the different colour header bg.
    // filter: invert(1);

    // Default to small scrolled size on mobile
    width: $header-scrolled-width;

    // Turn on the header resize on desktop
    @include media-breakpoint-up(md) {
        width: $header-starting-width;
    }
    .scrolled & {
        // filter: none;
        // Turn on the header resize on desktop
        @include media-breakpoint-up(md) {
            width: $header-scrolled-width;
        }
    }
}

.header-phone {
    line-height: 1.2;

    .phone-icon {
        img,
        svg {
            width: 2.2rem;
            @include media-breakpoint-down(sm) {
                width: 4rem;
            }
        }
    }

    // Got questions
    span {
        font-family: $body-font;
        font-style: normal;
        font-weight: 500;
        line-height: 1.1;
        letter-spacing: -0.02em;

        color: $highlights;
    }
    // Phone number text
    em {
        font-family: $headings-font;
        font-style: normal;
        font-weight: normal;
        font-size: 2.9rem;
        line-height: 1.1;
        letter-spacing: -0.02em;

        color: $phone-color;
    }
}
