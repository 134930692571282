// --------------------
// VARIABLES
//---------------------

$headerheight   : 120px;  // Change to approximate header height - adds padding to first section
$header-starting-width: 215px;
$header-scrolled-width: 150px;

// Colors
$body-color			: #505050;
$spacer-color		: #DED0C0;	// spacer
$primary        	: #5EC09B;
$secondary      	: #F0FCF7;
$bg-light-green		: #0b0c0c;	//green bg
$bg-light-grey		: #FCF9F9;	//grey bg
$highlights			: #31A2AC;	// phone, quotes, ol
$phone-color		: #152137;	// phone2	

$headerbg       	: #fff; // Header scrolled background color.
$headings-font		: 'DM Serif Text', serif;
$headings-color		: #353739; //h1, h2
$headings-sml-color	: #343851; //quote who, sml heading

$body-font			: 'Avenir Next', sans-serif;




$bg-table-active-color : #42c7b3;

$slider-title-color : #ffffff;
$slick-dot-color : #ffffff;
$slick-active-dot-color : #42c7b3;
// --------------------
// Container Options
//---------------------

// $grid-gutter-width: 100px;

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
);
  
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1140px,
	xxl: 1600px,
);
