.view-animation {
	opacity: 0;
}

.animated {
	opacity: 1;
	transition: 1s;
}

.fade-up,
.fade-down,
.fade-left,
.fade-right {
	&.animated {
		transform: translate3d(0, 0, 0);
	}
}

.scale-up,
.scale-down {
	&.animated {
		transform: scale(1);
	}
}

.bounce-left,
.bounce-right {
	&.animated {
		animation-duration: 1s;
		animation-fill-mode: both;
	}
}

.fade-up {
	transform: translate3d(0, 100px, 0);
}

.fade-down {
	transform: translate3d(0, -100px, 0);
}

.fade-left {
	transform: translate3d(100px, 0, 0);
}

.fade-right {
	transform: translate3d(-100px, 0, 0);
}

.scale-up {
	transform: scale(0.5);
}

.scale-down {
	transform: scale(1.5);
}

.bounce-left {
	&.animated {
		transform: translate3d(140px, -200px, -70px);
		animation-name: BounceLeft;
	}
}

.bounce-right {
	&.animated {
		transform: translate3d(-140px, -200px, -70px);
		animation-name: BounceRight;
	}
}

.delay {
	&-0 {
		animation-delay: 0s;
		transition-delay: 0s;
	}

	&-1 {
		animation-delay: .25s;
		transition-delay: .25s;
	}

	&-2 {
		animation-delay: .5s;
		transition-delay: .5s;
	}
	&-3 {
		animation-delay: .75s;
		transition-delay: .75s;
	}
	&-4 {
		animation-delay: 1s;
		transition-delay: 1s;
	}
	&-5 {
		animation-delay: 1.25s;
		transition-delay: 1.25s;
	}
	&-6 {
		animation-delay: 1.5s;
		transition-delay: 1.5s;
	}
}

@keyframes BounceLeft {
	0% {
		transform: translate3d(140px, -200px, -70px);
	}
	10% {
		transform: translate3d(130px, -100px, -70px);
	}
	20% {
		transform: translate3d(120px, 0, -60px);
	}
	30% {
		transform: translate3d(110px, -45px, -60px);
	}
	40% {
		transform: translate3d(100px, -90px, -50px);
	}
	50% {
		transform: translate3d(90px, -45px, -50px);
	}
	60% {
		transform: translate3d(80px, 0, -40px);
	}
	65% {
		transform: translate3d(70px, -30px, -40px);
	}
	70% {
		transform: translate3d(60px, -60px, -30px);
	}
	75% {
		transform: translate3d(50px, -30px, -30px);
	}
	80% {
		transform: translate3d(40px, 0, -20px);
	}
	85% {
		transform: translate3d(30px, -20px, -20px);
	}
	90% {
		transform: translate3d(20px, -40px, -10px);
	}
	95% {
		transform: translate3d(10px, -20px, -10px);
	}
	100% {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes BounceRight {
	0% {
		transform: translate3d(-140px, -200px, -70px);
	}
	10% {
		transform: translate3d(-130px, -100px, -70px);
	}
	20% {
		transform: translate3d(-120px, 0, -60px);
	}
	30% {
		transform: translate3d(-110px, -45px, -60px);
	}
	40% {
		transform: translate3d(-100px, -90px, -50px);
	}
	50% {
		transform: translate3d(-90px, -45px, -50px);
	}
	60% {
		transform: translate3d(-80px, 0, -40px);
	}
	65% {
		transform: translate3d(-70px, -30px, -40px);
	}
	70% {
		transform: translate3d(-60px, -60px, -30px);
	}
	75% {
		transform: translate3d(-50px, -30px, -30px);
	}
	80% {
		transform: translate3d(-40px, 0, -20px);
	}
	85% {
		transform: translate3d(-30px, -20px, -20px);
	}
	90% {
		transform: translate3d(-20px, -40px, -10px);
	}
	95% {
		transform: translate3d(-10px, -20px, -10px);
	}
	100% {
		transform: translate3d(0, 0, 0);
	}
}