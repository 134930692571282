// --------------------
// Include Fonts Here
// --------------------
@font-face {
    font-family: 'Avenir Next';
    src: url('./../fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
    url('./../fonts/AvenirNextLTPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DM Serif Text';
    src: url('./../fonts/DMSerifText-Regular.woff2') format('woff2'),
    url('./../fonts/DMSerifText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

$headings-font: 'DM Serif Text', serif;
$body-font: 'Avenir Next', sans-serif;

// --------------------
//  Include Basic Typography
// --------------------

:root {
    font-size: 10px; // 1rem = 10px
    // @include media-breakpoint-up(md) {
    //     font-size: 12px; //1rem = 12px
    // }
    // @include media-breakpoint-up(lg) {
    //     font-size: 14px; //1rem = 14px
    // }
    // @include media-breakpoint-up(lg) {
    //     font-size: 16px; //1rem = 16px
    // }
}

html, body {
    @include font-smoothing('on');
    font-family: $body-font;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.53;
    letter-spacing: -0.01em;
    color: $body-color;
}

p {
    margin-bottom: 1em;
}

// Headline padding
h1, .h1, 
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    line-height: 1.3;
    margin-bottom: 1em;
    font-family: $headings-font;
    font-style: normal;
    font-weight: normal;
    text-transform: capitalize;
    color: $headings-color;
}

h1, .h1 {
    font-size:2.3rem;  
    line-height: 1.35;
    @include media-breakpoint-up(sm) {
        font-size:2.3rem;  
    }
    @include media-breakpoint-up(lg) {
        font-size: 3.1rem;  
    }
    @include media-breakpoint-up(xl) {
        font-size: 3.7rem;  
    }
}

h2, .h2 {
    font-size: 2.2rem;  
    line-height: 1.15;
    @include media-breakpoint-up(lg) {
        font-size: 3.0rem;  
    }
    @include media-breakpoint-up(xl) {
        font-size: 3.6rem;  
    }
}

h3, .h3 {
    font-size: 3rem;  //Laptop: 30px, Ipads: 27px, Mobiles: 24px
    line-height: 1.15;
}

h4, .h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.53;
}

h5, .h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.53;
}

h6, .h6 {
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.53;
}

strong {
    font-family: DM Serif Text;
    font-style: normal;
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 1.21;
    color: $headings-sml-color;
}

// Hero Specific Font Sizes
.eyebrow {
    text-transform: uppercase;
    font-family: $body-font;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: $primary;
}

.subheadline {
}


// Helper classes
.spacer-top::before,
.spacer-bottom::after {
    display: block;
    content: "";
    margin: 2.7rem 0;
    width: 8rem;
    height: .2rem;
    background-color: $spacer-color;
    // .text-center&,
    .text-center & {
        margin-left: auto;
        margin-right: auto;
    }
    // .text-right&,
    .text-right & {
        margin-left: auto;
        margin-right: 0;
    }
}

// because .text-center& won't compile
.text-center.spacer-bottom::before,
.text-center.spacer-bottom::after {
	margin-left: auto;
	margin-right: auto;
}
// because .text-right& won't compile
.text-right.spacer-bottom::before,
.text-right.spacer-bottom::after {
	margin-left: auto;
	margin-right: 0;
}




// // --------------------
// // Include Fonts Here
// // --------------------
// @font-face {
//     font-family: 'Quart';
//     src: url('../../fonts/Quart-Regular.ttf') format('ttf'),
//     url('../../fonts/Quart-Regular.woff') format('woff'),
//     url('../../fonts/Quart-Regular.woff2') format('woff2');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Manrope';
//     src: url('../../fonts/Manrope-Regular.ttf') format('ttf'),
//     url('../../fonts/Manrope-Regular.woff') format('woff'),
//     url('../../fonts/Manrope-Regular.woff2') format('woff2');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Manrope';
//     src: url('../../fonts/Manrope-Bold.ttf') format('ttf'),
//     url('../../fonts/Manrope-Bold.woff') format('woff'),
//     url('../../fonts/Manrope-Bold.woff2') format('woff2');
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap;
// }

// $headings-font: 'Quart', sans-serif;
// $body-font: 'Manrope', sans-serif;

// // --------------------
// //  Include Basic Typography
// // --------------------

// :root {
//     font-size: 50%;  //1rem = 8px
//     @include media-breakpoint-up(md) {
//         font-size: 56.25%; //1rem = 9px
//     }
//     @include media-breakpoint-up(lg) {
//         font-size: 62.5%; // 1rem = 10px
//     }
// }

// html, body {
//     @include font-smoothing('on');
//     font-size: 2.1rem; //Laptop: 20px, Ipads: 18px, Mobiles: 16px
//     line-height: 1.5;
//     font-family: $body-font;
// }

// p {
//     margin-bottom: 3.3rem;
// }

// // Headline padding
// h1, .h1, 
// h2, .h2,
// h3, .h3,
// h4, .h4,
// h5, .h5,
// h6, .h6 {
//     line-height: 1.3;
//     margin-bottom: 2rem;
//     font-family: $headings-font;
//     font-weight: normal;
//     text-transform: uppercase;
//     color: $purple;
//     .text-light & {
//         color: $white;
//     }
// }


// h1, .h1 {
//     font-size: 2.2rem;
    
//     @include media-breakpoint-up(sm) {
//         font-size: 2.9rem;
//     }
//     @include media-breakpoint-up(md) {
//         font-size: 3.4rem;
//     }
//     @include media-breakpoint-up(lg) {
//         font-size: 4.2rem;
//     }
//     @include media-breakpoint-up(xl) {
//         font-size: 4.6rem;
//     }
// }

// h2, .h2 {
//     font-size: 2.6rem;

//     @include media-breakpoint-up(sm) {
//         font-size: 3.5rem;
//     }
//     @include media-breakpoint-up(lg) {
//         font-size: 3.8rem;
//     }
//     @include media-breakpoint-up(xl) {
//         font-size: 4.1rem;
//     }
// }

// h3, .h3 {
//     font-size: 3rem;  //Laptop: 30px, Ipads: 27px, Mobiles: 24px
// }

// h4, .h4 {
//     font-size: 1.8rem;
    
//     @include media-breakpoint-up(lg) {
//         font-size: 2.2rem;
//     }
// }

// h5, .h5 {
//     font-size: 2rem;  //Laptop: 20px, Ipads: 18px, Mobiles: 16px
// }

// h6, .h6 {
//     font-size: 1.8rem;  //Laptop: 18px, Ipads: 16.2px, Mobiles: 14.4px
// }

// strong {
// 	font-weight: 500; // TODO: Change this in boilerplate to font weight setting
// }

// // Hero Specific Font Sizes
// .eyebrow {
//     text-transform: uppercase;
// }

// .subheadline {
//     font-size: 2.2rem;
// }