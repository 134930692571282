.bg-dark,
.bg-light,
.bg-gradient,
.bg-light-green {
    // z-index: -1;
}

.bg-cover,
.bg-center,
.bg-top,
.bg-top-right,
.bg-right,
.bg-bottom-right,
.bg-bottom,
.bg-bottom-left,
.bg-left,
.bg-top-left {
    pointer-events: none;
    user-select: none;
    position: absolute;
    // z-index: -1;
}

.bg-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bg-top {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.bg-top-right {
    top: 0;
    right: 0;
}

.bg-right {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.bg-bottom-right {
    right: 0;
    bottom: 0;
}

.bg-bottom {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.bg-bottom-left {
    bottom: 0;
    left: 0;
}

.bg-left {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.bg-top-left {
    left: 0;
    top: 0;
}

.bg-stretch {
    width: 100%;
    object-fit: cover;
    display: flex;
}

.bg-cover {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
}
