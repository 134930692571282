form {
    input[type=text],
    input[type=email],
    input[type=tel],
    textarea,
    select,
    fieldset {
        width: 100%;
        border: none;
        margin-bottom: 15px;
        border-radius: 5px;

        font-family: $body-font;
        font-size: 1.5rem;
        line-height: 1.35;
        color: $body-color;
        box-shadow: -25px 23.59px 36px -20px rgba(130, 198, 190, 0.1);
        padding: 26px 26px 26px 54px;

        @include media-breakpoint-up(md) {
            // padding: 20px 15px 20px 70px;
        }
        &:-internal-autofill-selected {
            background-color: $white !important;
            background-image: none !important;
            color: -internal-light-dark-color(black, white) !important;
        }
        &::placeholder {
            font-family: $body-font;
            color: $body-color;
        }
    }

    textarea {
        height: 210px !important;
    }

    select {
        appearance: none;
    }

    label {
        position: relative;
        width: 100%;
        margin-bottom: 0;
        line-height: 1;
    }

    fieldset {
        position: relative;
        .label {
            line-height: 1.8;
        }
        label {
            padding-left: 20px;
        }
        .form-check-input {
            margin-top: 0;
            margin-left: 0;
            top: 0;
            left: 0;
            line-height: 2rem;
        }
    }

    .icon {
        position: absolute;
        top: 28px;
        left: 22px;
        width: 1.2em;
        z-index: 999;

        filter: drop-shadow(0 4px 8px rgba(94,192,155,0.5));
        @include media-breakpoint-up(md) {
            left: 25px;
        }
    }
    .icon-arrow {
        left: auto;
        right: 22px;
        width: 0.7em;
        pointer-events: none;
    }

    label.error,
    .field-error {
        display: block;
        margin-bottom: 20px;
        margin-top: -10px;
        color: red;
        font-size: 1.9rem;
    }
    .form-messages {
        text-align: center;
        &.success {
            color: green;
        }
        &.error {
            color: red;
        }
    }
}