
ul, ol {
	&.styled-list {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			position: relative;
            margin-bottom: 2rem;
        }
	}
}

ul.styled-list {
	li {
		img, 
		svg {
			max-width: 20px;
			filter: drop-shadow(0px 6px 20px rgba(94, 192, 155, 0.52));
			margin-right: 17px;
		}
	}
}

ol.styled-list {
	counter-reset: ol-counter;
	li {
		counter-increment: ol-counter;
        padding: 51px 27px 39px 27px;
		margin-left: 20px;
		margin-bottom: 43px;
		@include media-breakpoint-up(md) {
			margin-bottom: 2.1rem;
            padding: 39px 27px 39px 71px;
			margin-left: 37px;
		}
		&::before {
			content: counter(ol-counter);
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			border-radius: 50%;
			color: white;
			background: $highlights;
			box-shadow: 0px 30px 40px -10px rgba(94, 192, 155, 0.16);
			font-family: $headings-font;
			font-size: 2.6rem;
			line-height: 1.15;
			width: 60px;
			height: 60px;
			line-height: 60px;
			top: -30px;
			left: 50%;
			margin-left: -30px;
			@include media-breakpoint-up(md) {
				font-size: 3.6rem;
				top: 39px;
				left: -37px;
				width: 75px;
				height: 75px;
				line-height: 75px;
				margin-left: 0;
			}
		}
	}
}
