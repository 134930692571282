.swiper-container {
	width: 100%;
	&.mobile {
		@include media-breakpoint-up(lg) {
			pointer-events: none;
			.swiper-slide {
				flex-shrink: unset;
			}
		}
	}
	section.brands & {
		.swiper-wrapper {
			@include media-breakpoint-up(lg) {
				transform: unset !important;				
			}
		}
	}
	.swiper-slide {
		transition: .4s;
		p {
			max-width: 80%;
			@include media-breakpoint-up(lg) {
				max-width: 70%;
			}
		}
		.play-button p {
			max-width: 100%;
		}
	}

	.swiper-pagination {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		.swiper-pagination-bullet {
			outline: none;
			width: 1.8rem;
			height: 1.8rem;
			border-radius: 1.8rem;
			background: $secondary !important;
			opacity: 1;
			margin: 4px;
			&.swiper-pagination-bullet-active {
				background: $primary !important;
			}
		}
	}

	.swiper-button-prev, .swiper-button-next {
		outline: none;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		transition: .4s;
		cursor: pointer;
		box-shadow: 0px 30px 40px -10px rgba(94, 192, 155, 0.16);
	
	
		@include media-breakpoint-up(md) {
			width: 72px;
			height: 72px;
		}
		&:after {
			content: none;
		}
		img, svg {
			width: 50px;
			height: 50px;
			@include media-breakpoint-up(md) {
				width: 72px;
				height: 72px;
			}
		}
	}
	
	.swiper-button-prev {
		left: 0px;
		@include media-breakpoint-up(lg) {
			left: -80px;
		}
	}
	.swiper-button-next {
		right: 0px;
		@include media-breakpoint-up(lg) {
			right: -80px;
		}
	}

	&.gallery-thumbs {
		.swiper-slide {
			opacity: 0.9;
		}
		.swiper-slide-thumb-active {
			opacity: 1;
		}
	}
}
