// Helpers
.bg-light-green {
    background-color: #f0fcf7;
}
.bg-light-green-safetext {
    text-shadow: 0 0 20px $bg-light-green, 0 0 20px $bg-light-green, 0 0 20px $bg-light-green, 0 0 20px $bg-light-green;
}
.bg-gradient {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%), rgba(240, 252, 247, 0.5);
}
.text-light {
    color: #ffffff !important;
}

.rating5 {
    vertical-align: baseline;
    width: auto;
    height: 1em;
}

.rating5_head {
    width: auto;
    margin: 1.5rem auto;
    height: 3.3rem;
}

.two-column .col,
.two-column [class*="col-"] {
    padding-right: 50px;
    padding-left: 50px;
}

.swiper-button-prev, .swiper-button-next {
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: .4s;
    cursor: pointer;
    box-shadow: 0px 30px 40px -10px rgba(94, 192, 155, 0.16);

	
    position: relative;
    top: 25px;




    @include media-breakpoint-up(lg) {
        position: absolute;
        width: 72px;
        height: 72px;
    }
    &:after {
        content: none;
    }
    img, svg {
        width: 50px;
        height: 50px;
        @include media-breakpoint-up(lg) {
            width: 72px;
            height: 72px;
        }
    }
}

.swiper-button-prev {
    left: 20%;
    float: left;
    @include media-breakpoint-up(lg) {
        left: -80px;
    }
}
.swiper-button-next {
    right: 20%;
    float: right;
    @include media-breakpoint-up(lg) {
        right: -80px;
    }
}


// Styling
#hero {
    .img-wrap {
        position: relative;
        @include media-breakpoint-up(lg) {
            padding-left: 34px;
        }
        > a {
            position: absolute;
            width:33%;
            bottom: 0;
            left: 0;
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.07);
            }
        }
    }
}

#hero,
#ebook-hero {
    padding: 140px 0 60px;
    @include media-breakpoint-up(md) {
        padding: 160px 30px 150px 30px;
    }
    @include media-breakpoint-up(lg) {
        padding: 180px 60px 170px 60px;
    }
    .bg-bottom-right {
        width: 35%;
    }

    //ebook
    .bg-right {
        width: 45%;
    }
    .ebook {
        @include media-breakpoint-down(sm) {
            margin-right: -20%
        }
    }
    //thank-you
    .bg-top-left {
        width: 25%;
    }

    .btn-section {
        margin-left: 0;
        margin-right: 0;
    }
}

#section-2 {
    margin-top: -48px;

    .bg-top {
        height: 50%;
    }
    padding: 0;
    .bg-white {
        box-shadow: -25px 23.595px 36px -20px rgba(130, 198, 190, 0.1);
        border-radius: 5px;
        padding: 16px;
        h5 {
            font-family: $body-font;
            line-height: 1.45;

            color: $body-color;
        }
        strong {
            font-size: 2.1rem;
            line-height: 1.05;
        }
    }
}

#section-3 {
    .bg-light {
        position: relative;
        font-style: italic;
        border-radius: 10px;
        padding: 30px 20px;
        .icon-quote {
            width: 56px;
            position: absolute;
            top: -20px;
            right: 30px;
        }
        p {
            font-size: 22px;
            line-height: 1.16;
        }
        strong {
            font-size: 21px;
        }

        .photo {
            display: none;
        }

        @include media-breakpoint-up(md) {
            padding: 60px 60px 60px 180px;
            margin-left: 90px;
            .photo {
                display: block;
                width: 300px;
                position: absolute;
                left: -150px;
                top: -35px;
            }
            .quote {
                right: 60px;
            }
        }
    }
}

// TODO bg covering button???1!??
#section-5 {
    h2 {
        line-height: 1.15;
    }
}

#section-6 {
    .bg-top-left {
        width: 30%;
    }
    .bg-white {
        background: #ffffff;
        box-shadow: -8px 46px 40px -17px rgba(94, 192, 155, 0.1);
        border-radius: 10px;

        h5 {
            padding: 0 30px;
            font-size: 21px;
            line-height: 1.05;
            color: $headings-sml-color;
        }
        p {
            padding: 0 30px;
        }
        .recovered {
            font-family: $headings-font;
            color: $highlights;
        }
    }
}

#section-7 {
    .bg-top-left {
        width: 30%;
    }
}

#section-8 {
    .bg-bottom-right {
        width: 25%;
    }
    .team-image {
        margin: 0 -10%;
        width: 120%;
    }
}

#section-9 {
    .bg-top-left {
        width: 30%;
    }
    .bg-bottom-right {
        width: 45%;
    }
    .bg-white {
        border-radius: 10px;
        line-height: 1.45;
        box-shadow: -8px 46px 40px -17px rgba(222, 208, 192, 0.2);
    }
}

#section-12 {
    .container {
        @include media-breakpoint-up(lg) {
            max-width: 100%;
            width: 1340px;
        }
    }
    .video-container {
        border-radius: 10px;
        box-shadow: -8px 46px 40px -17px rgba(94, 192, 155, 0.1);
    }

    .overlay {

    }
    .play-button {
        width: 115px;
        box-shadow: none;
    }
    .vid-label {
        display: inline-block;
        margin: 20px auto;
        text-align: center;
        width: 250px;

        background: #ffffff;
        box-shadow: -3.53571px 20.3304px 17.6786px -7.51339px rgba(94, 192, 155, 0.1);
        border-radius: 4.41964px;

        font-family: DM Serif Text;
        font-size: 2rem;
        line-height: 2.08;
    }
    .swiper-button-next, .swiper-button-prev {
        @include media-breakpoint-up(lg) {
            top: 45%;
            transform: translateY(-50%);
        }
    }
}

#section-13 {
    .bg-bottom-left {
        width: 25%;
    }
    .guarantee {
        width: 270px;
    }
    .bg-white {
        margin-top: 43px;
        padding: 70px 42px 42px 42px;
        background: #ffffff;
        box-shadow: -8px 46px 40px -17px rgba(94, 192, 155, 0.1);
        border-radius: 10px;
    }
    .icon {
        box-shadow: 0px 18px 40px -14px rgba(94, 192, 155, 0.25);
        position: absolute;
        top: 0;
        left: 50%;
        width: 85px;
        background: #ffffff;
        border-radius: 50%;
        padding: 20px;
        margin-top: -42.5px;
        margin-left: -42.5px;
    }

    h3 {
        font-size: 21px;
        line-height: 1.05;
    }
}

#section-14,
#ebook-3 {
    .testimonial {
        padding: 30px 30px 40px 30px;
        border: 0.440804px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        box-shadow: -7.05287px 40.554px 35.2643px -14.9873px rgba(94, 192, 155, 0.1);
        border-radius: 8.81608px;

        dt {
            float: left;
            min-width: 90px;
            clear: both;
            font-family: DM Serif Text;
            font-size: 1.5rem;
            line-height: 42px;
            letter-spacing: 0em;
            @include media-breakpoint-up(md) {
                font-size: 2rem;
            }
        }
        dd {
            margin-bottom: 0;
            font-size: 1.5rem;
            line-height: 42px;
            letter-spacing: 0em;
            @include media-breakpoint-up(md) {
                font-size: 2rem;
            }
        }
        dd.name {
            font-family: DM Serif Text;
            color: $highlights;
        }
        dd.rating {
            font-size: 2rem;
        }
        .desc {
            margin-top: 8px;
            line-height: 34px;
        }
        dt.skills {
            font-size: 1.5rem;
        }
        dd.skills {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            text-align: right;
            font-size: 1.5rem;
        }
    }
}

#section-15 {
    .bg-bottom-right {
        width: 25%;
    }
    .bonus {
        margin-top: -5%;
    }
}

#section-16 {
    .bg-left {
        width: 15%;
    }
}

#last-section {
    padding-bottom: 300px;
    @include media-breakpoint-up(sm) {
        padding-bottom: 400px;
    }
    @include media-breakpoint-up(md) {
        padding-bottom: 600px;
    }
    @include media-breakpoint-up(lg) {
        padding-bottom: 800px;
    }
    @include media-breakpoint-up(xl) {
        padding-bottom: 900px;
    }

    .background-image-container img {
        object-position: center bottom;
        object-fit: contain;
    }

    //ebook
    .bg-top-left {
        width: 15%;
    }
}
